<template>
  <div class="about_us_container">
    <b-img src="@/assets/aboutus/aboutbg.png" fluid class="aboutus_bg"></b-img>
    <div class="container">
      <div class="text-center head_title">
        <h3 class="m-3">Our Mission is to Make Crypto == Currency</h3>
        <p style="font-size: 0.8rem;">We believe that cryptocurrency is the future of money. Our team is building the technologies to bring this
          future to fruition. We are engineers, builders and enthusiasts who love Bitcoin and the change it will bring to
          the world. We hope our efforts drive adoption of cryptocurrency and help educate consumers on the benefits of
          crypto for payments.</p>
      </div>
      <!-- <b-row v-for="(person , index) in personTops" :key="index" class="person_content" style="margin-bottom: 3rem;" >
        <b-col :sm="6" :order-sm="person.imgOrder" class="text-center">
          <b-img :src="person.pImg" fluid style="width: 80%;"></b-img>
        </b-col>
        <b-col :sm="6" :order-sm="1" class="mt-3">
          <h3>{{ person.name }}</h3>
          <div class="text-right mr-2">{{ person.position }}</div>
          <div >{{ person.introduce }}</div>
          <div class="d-flex justify-content-end contacts">
            <i class="icon" :class="'icon-' + [item]" v-for="(item,i) in person.contact" :key="i"></i>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col :lg="4" :md="6" v-for="(person , index) in personList" :key="index" class="text-center mb-3 person_content" >
          <b-img :src="person.pImg" rounded="circle" fluid style="width: 12rem; height: 12rem;"></b-img>
          <div class="mt-3 name">{{ person.name }}</div>
          <div class="position">{{ person.position }}</div>
          <div class="introduce">{{ person.introduce }}</div>
          <div class="d-flex justify-content-center contacts">
            <i class="icon" :class="'icon-' + [item]" v-for="(item,i) in person.contact" :key="i"></i>
          </div>
        </b-col>
      </b-row> -->
      <!-- <div class="d-flex justify-content-center align-items-center">
        <div class="donate_btn d-flex align-items-center">
          <h5>Donate with</h5>
          <h1 class="ml-2"><i>bitcoin</i></h1>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        personTops: [
          {
            pImg: require("@/assets/aboutus/people2.png"),
            name: "Ken Kruger",
            position: "Founder & CEO",
            introduce: "Ken is a former Lockheed Martin engineer with degrees from Cornell and Columbia Universities. He has a pet turtle named Charles.",
            contact: ["twitter" , "linkedin"],
            imgOrder: 2,
          },{
            pImg: require("@/assets/aboutus/people2.png"),
            name: "Richard Mangione",
            position: "Director Of Engineering",
            introduce: "Rich was previously a Tech Lead at ALICE during its acquisition by Expedia and is a graduate of Harvard University. He holds a US patent and once founded a pasta sauce company.",
            contact: ["linkedin"],
            imgOrder: 1,
          }
        ],
        personList: [{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Niko Klein",
          position: "Director of Business Operations",
          introduce: "Niko was a Director and Head of Research at Imprint Capital Advisors, acquired in 2014 by Goldman Sachs, and a graduate of Columbia University. He is an avid cook and sauna-enthusiast.",
          contact: ["twitter" , "linkedin"]
        },{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Khurram Kalim",
          position: "Director of Customer Success",
          introduce: "Ken is a former Lockheed Martin engineer with degrees from Cornell and Columbia Universities. He has a pet turtle named Charles.",
          contact: ["twitter" , "linkedin"]
        },{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Fox Holt",
          position: "Director of Sales",
          introduce: "Fox received his MBA from Cornell University, was a Director of Sales at GE and commercialized semiconductor research out of Cornell. He is a Texan.",
          contact: ["twitter" , "linkedin"]
        },{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Eric Liang",
          position: "Software Engineer",
          introduce: "Eric graduated from UC Irvine with a degree in Software Engineering. He is a loyal Golden State Warriors fan and collects sneakers.",
          contact: ["twitter" , "linkedin"]
        },{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Gabriel Bolzi",
          position: "Fullstack Engineer",
          introduce: "Gabriel is a software engineer with a strong background in financial systems. He founded two startups in Brazil, loves art, and holds painting days at his house.",
          contact: ["linkedin"]
        },{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Matt Haymond",
          position: "Analyst",
          introduce: "Matt is a data enthusiast with a background in Industrial Engineering from Cal Poly, San Luis Obispo. He enjoys staying active by going to the gym or getting outside with his family.",
          contact: ["linkedin"]
        },{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Noah Swope",
          position: "Business Development Associate",
          introduce: "Noah is currently a student at Texas A&M University where he is studying economics and finance. He loves to work on his family farm in his spare time.",
          contact: ["twitter" , "linkedin"]
        },{
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Alejandro Gala",
          position: "Social Media Marketer",
          introduce: "Alejandro is a student with deep interest in the crypto ecosystem. Aside from pursuing blockchain development, he loves to play basketball in his free time.",
          contact: [ "linkedin"]
        }, {
          pImg: require("@/assets/aboutus/people.jpg"),
          name: "Faisal Ali",
          position: "Software Engineer",
          introduce: "Faisal was a senior software developer at Paypal. He has a strong background in financial and healthcare domain and is a graduate from IIT. He loves playing chess in his free time, and is a big fan of tennis.",
          contact: ["twitter" , "linkedin"]
        }, ],
        bgObj: {
          bg: "",
          
        },
      }
    },
    created() {
      this.$emit('imageBg', this.bgObj)
    },
  }
</script>

<style lang="scss">
  .about_us_container {
    color: #333;
    
    .aboutus_bg {
      width: 100%;
      margin-top: -5rem;
    }
    
    
    .head_title {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    
    .person_content {
      color: #333;
      .name {
        font-size: 1.1rem;
      }
      .position {
        font-size: 0.8rem;
      }
      
      .introduce {
        font-size: 0.8rem;
      }
      
      .contacts {
        margin: 1rem 0;
        i {
          color: #007BFF;
          font-size: 1.5rem;
        }
      }
    }
    
    .donate_btn {
      background-color: #333;
      color: #fff;
      padding: 1rem 3rem;
      border-radius: 0.2rem;
      
      i {
        font-weight: bolder;
      }
    }
    
  }
</style>
